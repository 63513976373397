import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import './style.scss'

const BaseCard = ({
  className,
  style,
  theme,
  prefix,
  title,
  desc,
  children,
}) => (
  <div className={cx('card', className, `theme-${theme}`)} style={style}>
    {prefix && <div className="prefix">{prefix}</div>}
    {title && <h1 className="title">{title}</h1>}
    {desc && <p className="desc">{desc}</p>}
    {children}
  </div>
)

BaseCard.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  theme: PropTypes.oneOf(['white', 'light']),
}

BaseCard.defaultProps = {
  theme: 'white',
}

export default BaseCard
