import React from 'react'
import cx from 'classnames'

import './style.scss'

const Row = ({ className, style, alignItems, justifyContent, children }) => (
  <div
    className={cx(
      'row',
      alignItems && `row-align-items-${alignItems}`,
      justifyContent && `row-justify-content-${justifyContent}`,
      className
    )}
    style={style}
  >
    {children}
  </div>
)

const Col = ({
  className,
  style,
  children,
  span,
  sm,
  md,
  lg,
  xl,
  ml,
  mlSm,
  mlMd,
  mlLg,
  mlXl,
  mr,
  mrSm,
  mrMd,
  mrLg,
  mrXl,
  order,
  orderSm,
  orderMd,
  orderLg,
  orderXl,
  alignSelf,
  onClick,
}) => (
  <div
    className={cx(
      'col',
      span && `col-${span}`,
      sm && `col-sm-${sm}`,
      md && `col-md-${md}`,
      lg && `col-lg-${lg}`,
      xl && `col-xl-${xl}`,
      ml && `col-ml-${ml}`,
      mlSm && `col-ml-sm-${mlSm}`,
      mlMd && `col-ml-md-${mlMd}`,
      mlLg && `col-ml-lg-${mlLg}`,
      mlXl && `col-ml-xl-${mlXl}`,
      mr && `col-mr-${mr}`,
      mrSm && `col-mr-sm-${mrSm}`,
      mrMd && `col-mr-md-${mrMd}`,
      mrLg && `col-mr-lg-${mrLg}`,
      mrXl && `col-mr-xl-${mrXl}`,
      order && `col-order-${order}`,
      orderSm && `col-order-sm-${orderSm}`,
      orderMd && `col-order-md-${orderMd}`,
      orderLg && `col-order-lg-${orderLg}`,
      orderXl && `col-order-xl-${orderXl}`,
      alignSelf && `col-align-self-${alignSelf}`,
      className
    )}
    style={style}
    onClick={onClick}
    aria-hidden="true"
  >
    {children}
  </div>
)

Row.Break = () => <div style={{ width: '100%' }} />

export { Row, Col }
