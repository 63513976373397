import React from 'react'
import Slick from 'react-slick'
import cx from 'classnames'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './style.scss'

export const Swiper = ({ className, children, style, ...rest }) => (
  <Slick
    className={cx('h-swiper', className)}
    style={style}
    arrows={false}
    dots
    {...rest}
  >
    {children}
  </Slick>
)

export default Swiper
