import React from 'react'
import cx from 'classnames'
import BaseCard from '../BaseCard'
import { Row, Col } from '../../Row'

import './style.scss'

const RichCard = ({ theme, className, style, children, ...rest }) => (
  <BaseCard theme={theme} className={cx('rich-card', className)} style={style}>
    <Row
      alignItems="center"
      justifyContent="space-between"
      style={{ width: '100%' }}
      {...rest}
    >
      {children}
    </Row>
  </BaseCard>
)

RichCard.Content = ({
  title,
  titleHighlight,
  highlightColor,
  desc,
  children,
  ...rest
}) => (
  <Col className="main" {...rest}>
    <p className="title-highlight" style={{ color: highlightColor }}>
      {titleHighlight}
    </p>
    <div className="title">{title}</div>
    <div className="desc">{desc}</div>
    {children}
  </Col>
)

RichCard.Image = ({ width, height, image, desc, ...rest }) => (
  <Col
    style={{
      width,
      height,
    }}
    {...rest}
  >
    <div
      className={'image'}
      style={{
        backgroundImage: `url(${image})`,
      }}
    />
    {desc}
  </Col>
)

export default RichCard
