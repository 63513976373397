import React from 'react'
import { navigate } from 'gatsby'

import { Row, Col } from '@homepages/shared/components'

import Layout from '../components/Layout'
import useAnimation from '../hooks/useAnimation'
import { PATH } from '../constants'

import './index.scss'
import '../styles/fonts.scss'

const homeImg = require('../images/home/home-banner.png')
const aboutSpoonxLogo1 = require('../images/home/about-spoonx-logo1.png')
const aboutSpoonxLogo2 = require('../images/home/about-spoonx-logo2.png')
const aboutSpoonxImg = require('../images/home/about-spoonx.png')
const withYouImg1 = require('../images/home/with-you-1.png')
const withYouImg2 = require('../images/home/with-you-2.png')
const withYouImg3 = require('../images/home/with-you-3.png')
const marketImg = require('../images/home/market.png')
const mediaManagementImg = require('../images/home/management.png')
const productionImg = require('../images/home/production.png')
const duihaoIcon = require('../images/home/duihao.png')

const homeElementID = 'home-page'

const WithYouInfo = [
  {
    img: withYouImg1,
    title: 'We’ll get you started.',
    content:
      'English / Mandarin / Thai / Cantonese / Malay / Bahasa… and more languages to come.',
  },
  {
    img: withYouImg2,
    title: 'We’ll create the content.',
    content:
      'Our in-house production team will easily bring ideas from storyboarding to social-media-ready.',
  },
  {
    img: withYouImg3,
    title: 'We’ll grow your brand.',
    content:
      'Capture a wider market with effective paid advertising and influencer marketing strategies.',
  },
]

const MarketInfo = [
  {
    title: 'Why Influencer Marketing?',
    content: [
      'Drive authentic user-generated content',
      'Build your brand’s community of ambassadors',
      'Establish social proof for your products and services',
    ],
  },
]

const ManagementInfo = [
  {
    title: 'We’ll save you time and money with:',
    content: [
      'Content and advertising strategy',
      'End-to-end Production included',
      'Regular monitoring and reporting',
    ],
  },
]

const ProductionInfo = [
  {
    title: 'Lean on us to run your business more efficiently:',
    content: [
      'We’ll turn your concepts into a storyboard',
      'Our professional crew will handle production',
      'Deliverables will be social-media-ready',
    ],
  },
]

const Main = () => {
  const titleId = 'home-section-title'
  const contentId = 'home-section-content'
  const buttonId = 'home-section-button'
  const imgId = 'home-section-img'
  const imgDataId = 'home-section-data-img'

  useAnimation(`#${homeElementID}`, `#${titleId}`)
  useAnimation(`#${homeElementID}`, `#${contentId}`)
  useAnimation(`#${homeElementID}`, `#${buttonId}`, {
    animationClass: 'spoonx-animation-delay',
  })
  useAnimation(`#${homeElementID}`, `#${imgId}`)
  useAnimation(`#${homeElementID}`, `#${imgDataId}`)

  return (
    <section className="main">
      <div className="home-section main-content">
        <div className="main-content-left">
          <h2 id={titleId} className="title">
            Grow Your
            <br />
            Business Faster.
          </h2>
          <p id={contentId} className="content">
            Harness the power of digital marketing to take
            <br />
            your business to the next level.
          </p>
          <div id={buttonId} className="consultation-button">
            <button onClick={() => navigate(PATH.getStarted)}>
              Book Your Free Consultation
            </button>
          </div>
        </div>
        <div className="img-container">
          <img id={imgId} src={homeImg} className="img" />
        </div>
      </div>
    </section>
  )
}

const Customers = () => {
  const titleId = 'customers-title'
  useAnimation(`#${homeElementID}`, `#${titleId}`)

  return (
    <section className="customers">
      <div className="home-section">
        <div id={titleId}>
          <h2 className="customers-title">
            Trusted by the best in the business
          </h2>
          <div className="customers-sub-title">
            Over 20,000 lifestyle retailers love SpoonX and Zaihui.
          </div>
        </div>
        <div className="customers-content" />
      </div>
    </section>
  )
}

const AboutSpoonx = () => {
  const contentId = 'about-spoonx-content'
  const imgId = 'about-spoonx-img'
  useAnimation(`#${homeElementID}`, `#${contentId}`)
  useAnimation(`#${homeElementID}`, `#${imgId}`)

  return (
    <section className="about-spoonx">
      <div className="home-section">
        <div className="about-spoonx-content">
          <div className="left-container" id={contentId}>
            <h2 className="about-spoonx-title">
              <div>About SpoonX</div>
              <img className="logo1" src={aboutSpoonxLogo1} />
            </h2>
            <div className="contents">
              <div className="content">
                再惠 Zaihui has close to a decade of experience serving over
                28,000 brands in China and a solid funding track record.
              </div>
              <div className="content">
                Endorsed by the Economic Development Board’s{' '}
                <span className="tech">Tech@SG</span> program:
              </div>
              <div className="content">
                Zaihui entered the Southeast Asia markets in 2022 by opening its
                first overseas office in Singapore, under the name{' '}
                <span className="spoonx">SpoonX</span>.
              </div>
            </div>
            <img src={aboutSpoonxLogo2} className="logo2" />
          </div>
          <div className="right-container" id={imgId}>
            <img src={aboutSpoonxImg} className="about-spoonx-img" />
          </div>
        </div>
      </div>
    </section>
  )
}

const WithYouCard = ({ index, info }) => {
  const imgId = `with-you-img-${index}`
  const titleId = `with-you-title-${index}`
  const contentId = `with-you-content-${index}`
  useAnimation(`#${homeElementID}`, `#${imgId}`)
  useAnimation(`#${homeElementID}`, `#${titleId}`)
  useAnimation(`#${homeElementID}`, `#${contentId}`)

  return (
    <div key={index} className="we-were-item">
      <img id={imgId} className="img" src={info.img} />
      <div id={titleId} className="title">
        {info.title}
      </div>
      <div id={contentId} className="content">
        {info.content}
      </div>
    </div>
  )
}

const WithYou = () => {
  const titleId = 'with-you-title'
  useAnimation(`#${homeElementID}`, `#${titleId}`)

  return (
    <section className="with-you">
      <div className="home-section">
        <h2 id={titleId} className="with-you-title">
          We’ll be with you every step of the way.
        </h2>
        <div className="we-were-container">
          {WithYouInfo.map((item, index) => (
            <WithYouCard key={index} index={index} info={item} />
          ))}
        </div>
      </div>
    </section>
  )
}

const InfluencerMarketing = () => {
  const imgId = 'influencer-marketing-img'
  const titleId = 'influencer-marketing-title'
  const contentId = 'influencer-marketing-content'
  const buttonId = 'influencer-marketing-button'

  useAnimation(`#${homeElementID}`, `#${imgId}`)
  useAnimation(`#${homeElementID}`, `#${titleId}`)
  useAnimation(`#${homeElementID}`, `#${contentId}`)
  useAnimation(`#${homeElementID}`, `#${buttonId}`, {
    animationClass: 'spoonx-animation-delay',
  })

  return (
    <section className="influencer-marketing" id="marketing">
      <Row className="home-section">
        <Col span={6}>
          <img
            className="section-img"
            id={imgId}
            src={marketImg}
            alt="influencer-marketing"
          />
        </Col>
        <Col span={6} className="home-section-padding-left">
          <div>
            <h2 className="section-title" id={titleId}>
              Influencer Marketing
            </h2>
            <div className="section-content" id={contentId}>
              <p className="paragraph">
                Unlock the global networks and social influence of our database
                of influencers in different markets.
              </p>
              {MarketInfo.map((market, index) => (
                <div key={index} className="list-container">
                  <div className="paragraph-title padding-bottom-small">
                    {market.title}
                  </div>
                  {market.content.map(item => (
                    <li key={item} className="li-style-null">
                      <img src={duihaoIcon} alt="checked" />
                      <span>{item}</span>
                    </li>
                  ))}
                </div>
              ))}
            </div>
            <div id={buttonId} className="consultation-button">
              <button onClick={() => navigate(PATH.getStarted)}>
                Book Your Free Consultation
              </button>
            </div>
          </div>
        </Col>
      </Row>
    </section>
  )
}

const Management = () => {
  const imgId = 'media-management-img'
  const contentId = 'media-management-content'
  const buttonId = 'media-management-button'
  useAnimation(`#${homeElementID}`, `#${imgId}`)
  useAnimation(`#${homeElementID}`, `#${contentId}`)
  useAnimation(`#${homeElementID}`, `#${buttonId}`, {
    animationClass: 'spoonx-animation-delay',
  })

  return (
    <section className="media-management" id="management">
      <Row className="home-section">
        <Col span={6} className="home-section-padding-right">
          <div id={contentId}>
            <h2 className="section-title">Social Media Management</h2>
            <div className="section-content">
              <p className="paragraph">
                Be customer-ready with brand accounts and paid advertising set
                to capture your target markets.
              </p>
              {ManagementInfo.map((management, index) => (
                <div key={index} className="list-container">
                  <div className="paragraph-title padding-bottom-small">
                    {management.title}
                  </div>
                  {management.content.map(item => (
                    <li key={item} className="li-style-null">
                      <img src={duihaoIcon} alt="checked" />
                      <span>{item}</span>
                    </li>
                  ))}
                </div>
              ))}
            </div>
            <div id={buttonId} className="consultation-button">
              <button onClick={() => navigate(PATH.getStarted)}>
                Book Your Free Consultation
              </button>
            </div>
          </div>
        </Col>
        <Col span={6}>
          <img
            className="section-img"
            id={imgId}
            src={mediaManagementImg}
            alt="media-management"
          />
        </Col>
      </Row>
    </section>
  )
}

const Production = () => {
  const imgId = 'production-img'
  const contentId = 'production-content'
  const buttonId = 'production-button'
  useAnimation(`#${homeElementID}`, `#${imgId}`)
  useAnimation(`#${homeElementID}`, `#${contentId}`)
  useAnimation(`#${homeElementID}`, `#${buttonId}`, {
    animationClass: 'spoonx-animation-delay',
  })

  return (
    <section className="in-house-production" id="creation">
      <Row className="home-section">
        <Col span={6}>
          <img
            className="section-img"
            id={imgId}
            src={productionImg}
            alt="production"
          />
        </Col>
        <Col span={6} className="home-section-padding-left">
          <div id={contentId}>
            <h2 className="section-title">In-house Production</h2>
            <div className="section-content">
              <p className="paragraph">
                Are you or your team feeling stressed by the long list of tasks
                for content creation? Let our team of content strategists,
                scriptwriters, videographers/photographers, and editors, make
                your life easier.
              </p>
              {ProductionInfo.map((production, index) => (
                <div key={index} className="list-container">
                  <div className="paragraph-title padding-bottom-small">
                    {production.title}
                  </div>
                  {production.content.map(item => (
                    <li key={item} className="li-style-null">
                      <img src={duihaoIcon} alt="checked" />
                      <span>{item}</span>
                    </li>
                  ))}
                </div>
              ))}
            </div>
            <div id={buttonId} className="consultation-button">
              <button onClick={() => navigate(PATH.getStarted)}>
                Book Your Free Consultation
              </button>
            </div>
          </div>
        </Col>
      </Row>
    </section>
  )
}

const HomePage = () => {
  return (
    <Layout className="home-page" id={homeElementID}>
      <h1 className="ghost-page-header">Home</h1>
      <Main />
      <Customers />
      <AboutSpoonx />
      <WithYou />
      <InfluencerMarketing />
      <Management />
      <Production />
    </Layout>
  )
}

export default HomePage
